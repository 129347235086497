import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Container } from '../components/container'
import { SectionTitle } from '../components/section-title'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

const FAQPage = ({ data }) => {
  const { t } = useTranslation()
  const hrefLangLinks = {
    en: 'faq',
    fr: 'faq',
    nl: 'faq',
    de: 'faq',
  }
  const faqs = data.faq.nodes
  const { title, content, slug, seoMetaTags } = data.faqInfoPage

  return (
    <Layout hrefLangLinks={hrefLangLinks} seoMetaTags={seoMetaTags}>
      <Container color="bg-beige">
        <SectionTitle
          title={title}
          subtitle={content.replace(/<\/?[^>]+(>|$)/g, '')}
          centered={true}
          main={true}
        />
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
          {faqs.map(faq => (
            <li
              key={faq.title}
              className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <Link
                to={`${t('link-base')}${slug}/${faq.slug}`}
                className="flex-1 flex flex-col p-8 group"
              >
                {faq.icon && (
                  <img
                    className="w-12 h-12 flex-shrink-0 mx-auto"
                    src={faq.icon.url}
                    alt="faq image"
                  />
                )}

                <h3 className="mt-6 text-gray-900 text-sm font-medium group-hover:text-abel-green">
                  {faq.title}
                </h3>
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </Layout>
  )
}

export default FAQPage

export const blogPostQuery = graphql`
  query ($locale: String!) {
    faq: allDatoCmsFaq(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        slug
        icon {
          url
        }
      }
    }

    faqInfoPage: datoCmsInfoPage(code: { eq: "faq" }, locale: { eq: $locale }) {
      title
      content
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
